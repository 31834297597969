/* tslint:disable */

export enum ApCustomTypes {
	Data_ApLinqExtensionMethods = 'Data.ApLinqExtensionMethods',
	Data_TypeExtensions_DateTimeArrayMapper = 'Data.TypeExtensions.DateTimeArrayMapper',
	Data_TypeExtensions_DateTimeListMapper = 'Data.TypeExtensions.DateTimeListMapper',
	Data_TypeExtensions_IntListMapper = 'Data.TypeExtensions.IntListMapper',
	Data_TypeExtensions_RegionMapper = 'Data.TypeExtensions.RegionMapper',
	Data_TypeExtensions_StringArrayMapper = 'Data.TypeExtensions.StringArrayMapper',
	Data_TypeExtensions_StringListMapper = 'Data.TypeExtensions.StringListMapper',
	Data_TypeExtensions_UuidListUserType = 'Data.TypeExtensions.UuidListUserType',
	Data_TypeExtensions_Driver_NpgsqlDriverExtended = 'Data.TypeExtensions.Driver.NpgsqlDriverExtended',
	Data_TypeExtensions_Driver_NpgsqlExtendedSqlType = 'Data.TypeExtensions.Driver.NpgsqlExtendedSqlType',
	Data_UserType_BaseUserType = 'Data.UserType.BaseUserType',
	Data_UserType_CacheInfo = 'Data.UserType.CacheInfo',
	Data_UserType_CacheModel = 'Data.UserType.CacheModel',
	Data_UserType_IVersionsIndex = 'Data.UserType.IVersionsIndex',
	Data_UserType_GeometryType = 'Data.UserType.GeometryType',
	Data_UserType_GuidArrayType = 'Data.UserType.GuidArrayType',
	Data_UserType_IntArrayType = 'Data.UserType.IntArrayType',
	Data_UserType_IntervalType = 'Data.UserType.IntervalType',
	Data_UserType_JsonbUserType = 'Data.UserType.JsonbUserType',
	Data_UserType_PointType = 'Data.UserType.PointType',
	Data_UserType_StringArrayType = 'Data.UserType.StringArrayType',
	Data_Tracelog_Actions = 'Data.Tracelog.Actions',
	Data_Tracelog_ActionDataType = 'Data.Tracelog.ActionDataType',
	Data_Tracelog_ApiLog = 'Data.Tracelog.ApiLog',
	Data_Tracelog_Backend = 'Data.Tracelog.Backend',
	Data_Tracelog_Client = 'Data.Tracelog.Client',
	Data_Tracelog_ErpSyncLog = 'Data.Tracelog.ErpSyncLog',
	Data_Tracelog_ILog = 'Data.Tracelog.ILog',
	Data_Tracelog_MailCollectorLog = 'Data.Tracelog.MailCollectorLog',
	Data_Tracelog_MissingFactors = 'Data.Tracelog.MissingFactors',
	Data_Tracelog_MissingTranslations = 'Data.Tracelog.MissingTranslations',
	Data_Tracelog_MqttServiceLog = 'Data.Tracelog.MqttServiceLog',
	Data_Tracelog_PtsBrokerLog = 'Data.Tracelog.PtsBrokerLog',
	Data_Tracelog_SatBrokerLog = 'Data.Tracelog.SatBrokerLog',
	Data_Tracelog_SchedulerLog = 'Data.Tracelog.SchedulerLog',
	Data_Tracelog_TrialActivation = 'Data.Tracelog.TrialActivation',
	Data_TaskManagement_BasicFertilisationPlanBooks = 'Data.TaskManagement.BasicFertilisationPlanBooks',
	Data_TaskManagement_BasicFertilisationSummaries = 'Data.TaskManagement.BasicFertilisationSummaries',
	Data_TaskManagement_IPlanBook = 'Data.TaskManagement.IPlanBook',
	Data_TaskManagement_NFertilizationAdditionalValues = 'Data.TaskManagement.NFertilizationAdditionalValues',
	Data_TaskManagement_NFertilizationAdditionalValuesUserType = 'Data.TaskManagement.NFertilizationAdditionalValuesUserType',
	Data_TaskManagement_NFertilizationPlanBook = 'Data.TaskManagement.NFertilizationPlanBook',
	Data_TaskManagement_NFertilizationSummary = 'Data.TaskManagement.NFertilizationSummary',
	Data_TaskManagement_NminPotentials = 'Data.TaskManagement.NminPotentials',
	Data_TaskManagement_NutrientFertilisationPlanBooksStatisticValue = 'Data.TaskManagement.NutrientFertilisationPlanBooksStatisticValue',
	Data_TaskManagement_NutrientFertilisationPlanBooksStatisticValueUserType = 'Data.TaskManagement.NutrientFertilisationPlanBooksStatisticValueUserType',
	Data_TaskManagement_StatisticHistogram = 'Data.TaskManagement.StatisticHistogram',
	Data_TaskManagement_NutrientFertilisationStatisticValue = 'Data.TaskManagement.NutrientFertilisationStatisticValue',
	Data_TaskManagement_NutrientFertilisationValueUserType = 'Data.TaskManagement.NutrientFertilisationValueUserType',
	Data_TaskManagement_PlantProtectionPlanBook = 'Data.TaskManagement.PlantProtectionPlanBook',
	Data_TaskManagement_PlantProtectionProductList = 'Data.TaskManagement.PlantProtectionProductList',
	Data_TaskManagement_PlantProtectionProductListDetails = 'Data.TaskManagement.PlantProtectionProductListDetails',
	Data_TaskManagement_PlantProtectionProductListDetailsUserType = 'Data.TaskManagement.PlantProtectionProductListDetailsUserType',
	Data_TaskManagement_PlantProtectionSummary = 'Data.TaskManagement.PlantProtectionSummary',
	Data_TaskManagement_PlantProtectionPlanAdditionalValues = 'Data.TaskManagement.PlantProtectionPlanAdditionalValues',
	Data_TaskManagement_PlantProtectionPlanAdditionalValuesUserType = 'Data.TaskManagement.PlantProtectionPlanAdditionalValuesUserType',
	Data_TaskManagement_TaskManagementExportException = 'Data.TaskManagement.TaskManagementExportException',
	Data_TaskManagement_TaskManagementModule = 'Data.TaskManagement.TaskManagementModule',
	Data_TaskManagement_TaskManagementPlanBook = 'Data.TaskManagement.TaskManagementPlanBook',
	Data_TaskManagement_TaskManagementPlanSummary = 'Data.TaskManagement.TaskManagementPlanSummary',
	Data_TaskManagement_TaskManagementStatus = 'Data.TaskManagement.TaskManagementStatus',
	Data_TaskManagement_TaskManagementUnit = 'Data.TaskManagement.TaskManagementUnit',
	Data_Sensor_DatasetFieldMap = 'Data.Sensor.DatasetFieldMap',
	Data_Sensor_Logs = 'Data.Sensor.Logs',
	Data_Sensor_LogValues = 'Data.Sensor.LogValues',
	Data_Sensor_OrthoPhoto = 'Data.Sensor.OrthoPhoto',
	Data_Sensor_SensorFieldData = 'Data.Sensor.SensorFieldData',
	Data_Queue_ActionQueueItem = 'Data.Queue.ActionQueueItem',
	Data_PlantProtection_PlantProtectionLogEdit = 'Data.PlantProtection.PlantProtectionLogEdit',
	Data_PlantProtection_PlantProtectionLogEditProduct = 'Data.PlantProtection.PlantProtectionLogEditProduct',
	Data_PfBox_CropApplNum = 'Data.PfBox.CropApplNum',
	Data_Parameter_ListValuesMapping = 'Data.Parameter.ListValuesMapping',
	Data_Parameter_ListValuesMappingUserType = 'Data.Parameter.ListValuesMappingUserType',
	Data_Parameter_ParameterDefinition = 'Data.Parameter.ParameterDefinition',
	Data_Parameter_ParameterValue = 'Data.Parameter.ParameterValue',
	Data_Parameter_ParameterValueHistory = 'Data.Parameter.ParameterValueHistory',
	Data_Parameter_Enums_OperationType = 'Data.Parameter.Enums.OperationType',
	Data_Parameter_Enums_ParameterRefObjectType = 'Data.Parameter.Enums.ParameterRefObjectType',
	Data_Parameter_Enums_ParameterType = 'Data.Parameter.Enums.ParameterType',
	Data_Nutrients_BuRasterStatistic = 'Data.Nutrients.BuRasterStatistic',
	Data_Nutrients_FieldDistribution = 'Data.Nutrients.FieldDistribution',
	Data_Nutrients_FieldNutrientPlanningStatistic = 'Data.Nutrients.FieldNutrientPlanningStatistic',
	Data_Nutrients_StatisticHistogram = 'Data.Nutrients.StatisticHistogram',
	Data_Nutrients_FieldNutrientsDistribution = 'Data.Nutrients.FieldNutrientsDistribution',
	Data_Nutrients_Histogram = 'Data.Nutrients.Histogram',
	Data_Nutrients_FieldNutrientsMap = 'Data.Nutrients.FieldNutrientsMap',
	Data_Nutrients_GeometryValue = 'Data.Nutrients.GeometryValue',
	Data_Nutrients_IrRasterMetaData = 'Data.Nutrients.IrRasterMetaData',
	Data_Nutrients_IrRasterFieldStatisticUserType = 'Data.Nutrients.IrRasterFieldStatisticUserType',
	Data_Nutrients_NPlanningPolygon = 'Data.Nutrients.NPlanningPolygon',
	Data_Nutrients_NPlanningPolygonsUserData = 'Data.Nutrients.NPlanningPolygonsUserData',
	Data_Nutrients_NPlanningPolygonData = 'Data.Nutrients.NPlanningPolygonData',
	Data_Nutrients_NPlanningPolygonDataUserType = 'Data.Nutrients.NPlanningPolygonDataUserType',
	Data_Nutrients_NRasterStatistic = 'Data.Nutrients.NRasterStatistic',
	Data_Nutrients_NutrientPlanningPolygons = 'Data.Nutrients.NutrientPlanningPolygons',
	Data_Nutrients_NutrientsPlanningPolygonData = 'Data.Nutrients.NutrientsPlanningPolygonData',
	Data_Nutrients_NutrientsPlanningPolygonDataUserType = 'Data.Nutrients.NutrientsPlanningPolygonDataUserType',
	Data_Nutrients_PlRasterStatistic = 'Data.Nutrients.PlRasterStatistic',
	Data_Nutrients_RasterCreationResult = 'Data.Nutrients.RasterCreationResult',
	Data_Nutrients_RasterOrigin = 'Data.Nutrients.RasterOrigin',
	Data_Nutrients_RasterStatistic = 'Data.Nutrients.RasterStatistic',
	Data_Nutrients_RasterStatisticUserType = 'Data.Nutrients.RasterStatisticUserType',
	Data_Nutrients_FieldRasterStatisticUserType = 'Data.Nutrients.FieldRasterStatisticUserType',
	Data_Nutrients_RbRasterStatisticUserType = 'Data.Nutrients.RbRasterStatisticUserType',
	Data_Nutrients_RbRasterMetaData = 'Data.Nutrients.RbRasterMetaData',
	Data_Nutrients_RbStatistic = 'Data.Nutrients.RbStatistic',
	Data_Nutrients_SampleMethod = 'Data.Nutrients.SampleMethod',
	Data_Nutrients_SampleRegion = 'Data.Nutrients.SampleRegion',
	Data_Nutrients_SampleTracks = 'Data.Nutrients.SampleTracks',
	Data_Nutrients_SoilAnalysisGrid = 'Data.Nutrients.SoilAnalysisGrid',
	Data_Nutrients_SoilSample = 'Data.Nutrients.SoilSample',
	Data_Nutrients_SoilSampleField = 'Data.Nutrients.SoilSampleField',
	Data_Nutrients_SoilSampleFieldDistribution = 'Data.Nutrients.SoilSampleFieldDistribution',
	Data_Nutrients_SoilSampleFieldState = 'Data.Nutrients.SoilSampleFieldState',
	Data_Nutrients_UpdateFieldGeomsParameter = 'Data.Nutrients.UpdateFieldGeomsParameter',
	Data_Nutrients_WsvRasterMetaData = 'Data.Nutrients.WsvRasterMetaData',
	Data_Nutrients_WsvRasterFieldStatisticUserType = 'Data.Nutrients.WsvRasterFieldStatisticUserType',
	Data_Nutrients_WsvRasterStatistic = 'Data.Nutrients.WsvRasterStatistic',
	Data_NFertilization_CropEcN = 'Data.NFertilization.CropEcN',
	Data_NFertilization_CropEcSn = 'Data.NFertilization.CropEcSn',
	Data_NFertilization_NFertilizationDetail = 'Data.NFertilization.NFertilizationDetail',
	Data_NFertilization_NFertilizationNeedValue = 'Data.NFertilization.NFertilizationNeedValue',
	Data_NFertilization_NPlanningData = 'Data.NFertilization.NPlanningData',
	Data_NFertilization_NSensorRasterValues = 'Data.NFertilization.NSensorRasterValues',
	Data_NDI_CreateNIndexRasterResult = 'Data.NDI.CreateNIndexRasterResult',
	Data_NDI_NdiStatistic = 'Data.NDI.NdiStatistic',
	Data_NDI_NdiStatisticUserType = 'Data.NDI.NdiStatisticUserType',
	Data_Metrics_ActionCount = 'Data.Metrics.ActionCount',
	Data_Metrics_ActionDuration = 'Data.Metrics.ActionDuration',
	Data_Metrics_JobMetrics = 'Data.Metrics.JobMetrics',
	Data_Metrics_ModuleNavigation = 'Data.Metrics.ModuleNavigation',
	Data_MenuStructure_MenuChildren = 'Data.MenuStructure.MenuChildren',
	Data_MenuStructure_MenuEntry = 'Data.MenuStructure.MenuEntry',
	Data_Licensing_Feature = 'Data.Licensing.Feature',
	Data_Licensing_FeatureModul = 'Data.Licensing.FeatureModul',
	Data_Licensing_License = 'Data.Licensing.License',
	Data_Licensing_Enums_LicenseAdjustAction = 'Data.Licensing.Enums.LicenseAdjustAction',
	Data_Language_Language = 'Data.Language.Language',
	Data_Language_Translation = 'Data.Language.Translation',
	Data_Job_CreateBuRasterJobInput = 'Data.Job.CreateBuRasterJobInput',
	Data_Job_FieldRasterJobConfiguration = 'Data.Job.FieldRasterJobConfiguration',
	Data_Job_IMapFactoryRegexBuilder = 'Data.Job.IMapFactoryRegexBuilder',
	Data_Job_InvalidateMapFactoryCacheInput = 'Data.Job.InvalidateMapFactoryCacheInput',
	Data_Job_IpsImportJobConfiguration = 'Data.Job.IpsImportJobConfiguration',
	Data_Job_IInterpolationDatabaseProperty = 'Data.Job.IInterpolationDatabaseProperty',
	Data_Job_Jobs = 'Data.Job.Jobs',
	Data_Job_JobsChanges = 'Data.Job.JobsChanges',
	Data_Job_JobStatus = 'Data.Job.JobStatus',
	Data_Job_JobTypes = 'Data.Job.JobTypes',
	Data_Job_MapFactoryFarmIdInput = 'Data.Job.MapFactoryFarmIdInput',
	Data_Job_MapFactoryGuidInput = 'Data.Job.MapFactoryGuidInput',
	Data_Job_MapFactoryGuidListInput = 'Data.Job.MapFactoryGuidListInput',
	Data_Job_MapFactoryRegexInput = 'Data.Job.MapFactoryRegexInput',
	Data_Job_MapFactorySoilSampleFieldIdsInput = 'Data.Job.MapFactorySoilSampleFieldIdsInput',
	Data_Job_RasterJobConfiguration = 'Data.Job.RasterJobConfiguration',
	Data_Job_RecontouringJobConfiguration = 'Data.Job.RecontouringJobConfiguration',
	Data_Job_RefreshBuRasterJobInput = 'Data.Job.RefreshBuRasterJobInput',
	Data_Job_RefreshCeRasterJobInput = 'Data.Job.RefreshCeRasterJobInput',
	Data_Job_RefreshWsvRasterJobInput = 'Data.Job.RefreshWsvRasterJobInput',
	Data_Job_RemoteJobOptions = 'Data.Job.RemoteJobOptions',
	Data_Job_RemoteJobSshOptions = 'Data.Job.RemoteJobSshOptions',
	Data_Job_Schedule = 'Data.Job.Schedule',
	Data_Job_Configurations_Configuration = 'Data.Job.Configurations.Configuration',
	Data_Job_Configurations_MongoDbConfiguration = 'Data.Job.Configurations.MongoDbConfiguration',
	Data_Job_Configurations_NApplicationMapConfiguration = 'Data.Job.Configurations.NApplicationMapConfiguration',
	Data_Job_Configurations_NLogInterpolationJobConfiguration = 'Data.Job.Configurations.NLogInterpolationJobConfiguration',
	Data_Job_Configurations_NSensorLogfileMapData = 'Data.Job.Configurations.NSensorLogfileMapData',
	Data_Job_Configurations_NSensorLogFileParserData = 'Data.Job.Configurations.NSensorLogFileParserData',
	Data_Job_Configurations_NTransformJobConfiguration = 'Data.Job.Configurations.NTransformJobConfiguration',
	Data_Job_Configurations_NutrientPlanningJobConfiguration = 'Data.Job.Configurations.NutrientPlanningJobConfiguration',
	Data_Job_Configurations_PostgresConfiguration = 'Data.Job.Configurations.PostgresConfiguration',
	Data_Job_Configurations_PpApplicationMapConfiguration = 'Data.Job.Configurations.PpApplicationMapConfiguration',
	Data_Job_Configurations_PpLogInterpolationJobConfiguration = 'Data.Job.Configurations.PpLogInterpolationJobConfiguration',
	Data_Job_Configurations_RbRasterJobConfiguration = 'Data.Job.Configurations.RbRasterJobConfiguration',
	Data_Job_Configurations_SensorLogFileConverterData = 'Data.Job.Configurations.SensorLogFileConverterData',
	Data_IpsPlanning_DeletedSoilSampleField = 'Data.IpsPlanning.DeletedSoilSampleField',
	Data_IpsPlanning_IpsContouringSamples = 'Data.IpsPlanning.IpsContouringSamples',
	Data_IpsPlanning_SampleDateObject = 'Data.IpsPlanning.SampleDateObject',
	Data_IpsPlanning_SoilSamplePlanning = 'Data.IpsPlanning.SoilSamplePlanning',
	Data_IpsPlanning_SoilSamplePlanningExportData = 'Data.IpsPlanning.SoilSamplePlanningExportData',
	Data_Import_CsvColumn = 'Data.Import.CsvColumn',
	Data_Import_CsvHeaderColumn = 'Data.Import.CsvHeaderColumn',
	Data_Import_CsvHeaderType = 'Data.Import.CsvHeaderType',
	Data_Import_CsvType = 'Data.Import.CsvType',
	Data_Import_DataSet = 'Data.Import.DataSet',
	Data_Import_DataSetColumn = 'Data.Import.DataSetColumn',
	Data_Import_DataSetType = 'Data.Import.DataSetType',
	Data_Import_HeaderValues = 'Data.Import.HeaderValues',
	Data_Import_HeaderValuesUserType = 'Data.Import.HeaderValuesUserType',
	Data_Import_ImportDataSet = 'Data.Import.ImportDataSet',
	Data_Import_ImportFile = 'Data.Import.ImportFile',
	Data_Import_ImportState = 'Data.Import.ImportState',
	Data_Import_MongoFileRefDirection = 'Data.Import.MongoFileRefDirection',
	Data_Import_ImportResult = 'Data.Import.ImportResult',
	Data_Import_Log = 'Data.Import.Log',
	Data_Import_LogRecord = 'Data.Import.LogRecord',
	Data_Import_NSensorLogHeaderData = 'Data.Import.NSensorLogHeaderData',
	Data_Import_PlantProtectionSensorLogAgentData = 'Data.Import.PlantProtectionSensorLogAgentData',
	Data_Import_PlantProtectionSensorLogHeaderData = 'Data.Import.PlantProtectionSensorLogHeaderData',
	Data_Import_SensorLogHeaderData = 'Data.Import.SensorLogHeaderData',
	Data_GeometryEditor_GeometryUpdate = 'Data.GeometryEditor.GeometryUpdate',
	Data_GeometryEditor_GeometryUpdateType = 'Data.GeometryEditor.GeometryUpdateType',
	Data_Geometry_GeoJsonLine = 'Data.Geometry.GeoJsonLine',
	Data_Geometry_GeoJsonMultiLine = 'Data.Geometry.GeoJsonMultiLine',
	Data_Geometry_GeoJsonMultiPoint = 'Data.Geometry.GeoJsonMultiPoint',
	Data_Geometry_GeoJsonMultiPolygon = 'Data.Geometry.GeoJsonMultiPolygon',
	Data_Geometry_GeoJsonPoint = 'Data.Geometry.GeoJsonPoint',
	Data_Geometry_GeoJsonPolygon = 'Data.Geometry.GeoJsonPolygon',
	Data_FungidetectManagement_Fungidetect = 'Data.FungidetectManagement.Fungidetect',
	Data_FungidetectManagement_FungidetectDetail = 'Data.FungidetectManagement.FungidetectDetail',
	Data_Fields_MonitoringDetail = 'Data.Fields.MonitoringDetail',
	Data_Fields_MonitoringField = 'Data.Fields.MonitoringField',
	Data_Fields_MonitoringType = 'Data.Fields.MonitoringType',
	Data_FieldManagement_CropRotationAttributes = 'Data.FieldManagement.CropRotationAttributes',
	Data_FieldManagement_CropRotationCell = 'Data.FieldManagement.CropRotationCell',
	Data_FieldManagement_CropRotationCombined = 'Data.FieldManagement.CropRotationCombined',
	Data_FieldManagement_CropRotationNeed = 'Data.FieldManagement.CropRotationNeed',
	Data_FieldManagement_Field = 'Data.FieldManagement.Field',
	Data_FieldManagement_FieldBase = 'Data.FieldManagement.FieldBase',
	Data_FieldManagement_FieldCrop = 'Data.FieldManagement.FieldCrop',
	Data_FieldManagement_FieldCropExtensions = 'Data.FieldManagement.FieldCropExtensions',
	Data_FieldManagement_CropRotationYield = 'Data.FieldManagement.CropRotationYield',
	Data_FieldManagement_CropRotation = 'Data.FieldManagement.CropRotation',
	Data_FieldManagement_FieldCropRotation = 'Data.FieldManagement.FieldCropRotation',
	Data_FieldManagement_FieldCropRotationHistory = 'Data.FieldManagement.FieldCropRotationHistory',
	Data_FieldManagement_FieldCropIntersection = 'Data.FieldManagement.FieldCropIntersection',
	Data_FieldManagement_FieldGeom = 'Data.FieldManagement.FieldGeom',
	Data_FieldManagement_FieldGeomMin = 'Data.FieldManagement.FieldGeomMin',
	Data_FieldManagement_FieldGeomPackage = 'Data.FieldManagement.FieldGeomPackage',
	Data_FieldManagement_FieldGrowth = 'Data.FieldManagement.FieldGrowth',
	Data_FieldManagement_FieldIntegrateResult = 'Data.FieldManagement.FieldIntegrateResult',
	Data_FieldManagement_FieldMin = 'Data.FieldManagement.FieldMin',
	Data_FieldManagement_FieldYield = 'Data.FieldManagement.FieldYield',
	Data_Export_IsobusDDI = 'Data.Export.IsobusDDI',
	Data_DocuContext_DocuContextCreateModelBase = 'Data.DocuContext.DocuContextCreateModelBase',
	Data_DocuContext_DocuContextDeleteModelBase = 'Data.DocuContext.DocuContextDeleteModelBase',
	Data_DocuContext_DocuContextReadModelBase = 'Data.DocuContext.DocuContextReadModelBase',
	Data_DocuContext_DocuContextUpdateModelBase = 'Data.DocuContext.DocuContextUpdateModelBase',
	Data_DocuContext_MachineSlicesSet = 'Data.DocuContext.MachineSlicesSet',
	Data_DocuContext_UserData_ReadUserDataModel = 'Data.DocuContext.UserData.ReadUserDataModel',
	Data_DocuContext_Slice_CombinedSlice = 'Data.DocuContext.Slice.CombinedSlice',
	Data_DocuContext_Slice_Slice = 'Data.DocuContext.Slice.Slice',
	Data_DocuContext_Slice_SliceFilter = 'Data.DocuContext.Slice.SliceFilter',
	Data_DocuContext_Slice_SliceProcessing = 'Data.DocuContext.Slice.SliceProcessing',
	Data_DocuContext_Slice_SliceProcessingUserType = 'Data.DocuContext.Slice.SliceProcessingUserType',
	Data_DocuContext_MachinesLogger_CreateMachinesLoggerModel = 'Data.DocuContext.MachinesLogger.CreateMachinesLoggerModel',
	Data_DocuContext_MachinesLogger_MachinesLogger = 'Data.DocuContext.MachinesLogger.MachinesLogger',
	Data_DocuContext_MachinesLogger_ReadMachinesLoggerModel = 'Data.DocuContext.MachinesLogger.ReadMachinesLoggerModel',
	Data_DocuContext_MachinesLogger_UpdateMachinesLoggerModel = 'Data.DocuContext.MachinesLogger.UpdateMachinesLoggerModel',
	Data_DocuContext_Machine_CreateMachineModel = 'Data.DocuContext.Machine.CreateMachineModel',
	Data_DocuContext_Machine_Machine = 'Data.DocuContext.Machine.Machine',
	Data_DocuContext_Machine_MachineBreak = 'Data.DocuContext.Machine.MachineBreak',
	Data_DocuContext_Machine_ReadMachineModel = 'Data.DocuContext.Machine.ReadMachineModel',
	Data_DocuContext_Machine_UpdateMachineModel = 'Data.DocuContext.Machine.UpdateMachineModel',
	Data_DocuContext_Logger_CreateLoggerModel = 'Data.DocuContext.Logger.CreateLoggerModel',
	Data_DocuContext_Logger_Logger = 'Data.DocuContext.Logger.Logger',
	Data_DocuContext_Logger_ReadLoggerModel = 'Data.DocuContext.Logger.ReadLoggerModel',
	Data_DocuContext_Logger_SendTarget = 'Data.DocuContext.Logger.SendTarget',
	Data_DocuContext_Logger_UpdateLoggerModel = 'Data.DocuContext.Logger.UpdateLoggerModel',
	Data_DocuContext_Location_LocationModel = 'Data.DocuContext.Location.LocationModel',
	Data_DocuContext_Location_LocationsEnvelop = 'Data.DocuContext.Location.LocationsEnvelop',
	Data_DocuContext_Instrument_CreateInstrumentModel = 'Data.DocuContext.Instrument.CreateInstrumentModel',
	Data_DocuContext_Instrument_Instrument = 'Data.DocuContext.Instrument.Instrument',
	Data_DocuContext_Instrument_ReadInstrumentModel = 'Data.DocuContext.Instrument.ReadInstrumentModel',
	Data_DocuContext_Instrument_UpdateInstrumentModel = 'Data.DocuContext.Instrument.UpdateInstrumentModel',
	Data_DocuContext_FleetManagement_FieldAction = 'Data.DocuContext.FleetManagement.FieldAction',
	Data_DocuContext_FleetManagement_FleetAction = 'Data.DocuContext.FleetManagement.FleetAction',
	Data_DocuContext_FleetManagement_FleetActionAttachment = 'Data.DocuContext.FleetManagement.FleetActionAttachment',
	Data_DocuContext_DriversMachines_CreateDriversMachinesModel = 'Data.DocuContext.DriversMachines.CreateDriversMachinesModel',
	Data_DocuContext_DriversMachines_DriverMachine = 'Data.DocuContext.DriversMachines.DriverMachine',
	Data_DocuContext_DriversMachines_ReadDriversMachinesModel = 'Data.DocuContext.DriversMachines.ReadDriversMachinesModel',
	Data_DocuContext_DriversMachines_UpdateDriversMachinesModel = 'Data.DocuContext.DriversMachines.UpdateDriversMachinesModel',
	Data_DocuContext_Driver_CreateDriverModel = 'Data.DocuContext.Driver.CreateDriverModel',
	Data_DocuContext_Driver_Driver = 'Data.DocuContext.Driver.Driver',
	Data_DocuContext_Driver_ReadDriverModel = 'Data.DocuContext.Driver.ReadDriverModel',
	Data_DocuContext_Driver_UpdateDriverModel = 'Data.DocuContext.Driver.UpdateDriverModel',
	Data_DocuContext_Daystatus_Daystatus = 'Data.DocuContext.Daystatus.Daystatus',
	Data_DocuContext_Attachment_Attachment = 'Data.DocuContext.Attachment.Attachment',
	Data_DocuContext_Attachment_AttachmentApplication = 'Data.DocuContext.Attachment.AttachmentApplication',
	Data_DocuContext_Attachment_AttachmentApplicationUserType = 'Data.DocuContext.Attachment.AttachmentApplicationUserType',
	Data_DocuContext_Attachment_AttachmentMapping = 'Data.DocuContext.Attachment.AttachmentMapping',
	Data_DocuContext_Attachment_AttachmentMappingUserType = 'Data.DocuContext.Attachment.AttachmentMappingUserType',
	Data_DocuContext_ApplicationData_ReadApplicationModel = 'Data.DocuContext.ApplicationData.ReadApplicationModel',
	Data_Dashboard_News = 'Data.Dashboard.News',
	Data_Common_AgriportConstants = 'Data.Common.AgriportConstants',
	Data_Common_ApplicationMode = 'Data.Common.ApplicationMode',
	Data_Common_ContactInformation = 'Data.Common.ContactInformation',
	Data_Common_Country = 'Data.Common.Country',
	Data_Common_Element = 'Data.Common.Element',
	Data_Common_ExportFormat = 'Data.Common.ExportFormat',
	Data_Common_ExportTarget = 'Data.Common.ExportTarget',
	Data_Common_Factors = 'Data.Common.Factors',
	Data_Common_HelpArticles = 'Data.Common.HelpArticles',
	Data_Common_Notifications = 'Data.Common.Notifications',
	Data_Common_NPlanningBand = 'Data.Common.NPlanningBand',
	Data_Common_NUptakeFactors = 'Data.Common.NUptakeFactors',
	Data_Common_OperationMode = 'Data.Common.OperationMode',
	Data_Common_Unit = 'Data.Common.Unit',
	Data_Client_ClientException = 'Data.Client.ClientException',
	Data_BaseData_CropCategories = 'Data.BaseData.CropCategories',
	Data_BaseData_CropGroups = 'Data.BaseData.CropGroups',
	Data_BaseData_CropTypes = 'Data.BaseData.CropTypes',
	Data_BaseData_CropTypesDetails = 'Data.BaseData.CropTypesDetails',
	Data_BaseData_DateRange = 'Data.BaseData.DateRange',
	Data_BaseData_DateRangeAssigment = 'Data.BaseData.DateRangeAssigment',
	Data_BaseData_EffectiveRange = 'Data.BaseData.EffectiveRange',
	Data_BaseData_FarmCropGroup = 'Data.BaseData.FarmCropGroup',
	Data_BaseData_FertilizerInorg = 'Data.BaseData.FertilizerInorg',
	Data_BaseData_FertilizerOrg = 'Data.BaseData.FertilizerOrg',
	Data_BaseData_IProduct = 'Data.BaseData.IProduct',
	Data_BaseData_Landuse = 'Data.BaseData.Landuse',
	Data_BaseData_MachineType = 'Data.BaseData.MachineType',
	Data_BaseData_PlantProtectionProduct = 'Data.BaseData.PlantProtectionProduct',
	Data_BaseData_PlantProtectionProductDetails = 'Data.BaseData.PlantProtectionProductDetails',
	Data_BaseData_PlantProtectionProductDetailsWriteable = 'Data.BaseData.PlantProtectionProductDetailsWriteable',
	Data_BaseData_PlantProtectionProductWriteable = 'Data.BaseData.PlantProtectionProductWriteable',
	Data_BaseData_SelectableCrops = 'Data.BaseData.SelectableCrops',
	Data_BaseData_SelectableFertilizer = 'Data.BaseData.SelectableFertilizer',
	Data_BaseData_SelectablePpProduct = 'Data.BaseData.SelectablePpProduct',
	Data_BaseData_SelectableVariety = 'Data.BaseData.SelectableVariety',
	Data_BaseData_Settings = 'Data.BaseData.Settings',
	Data_BaseData_SubFarms = 'Data.BaseData.SubFarms',
	Data_BaseData_Terminals = 'Data.BaseData.Terminals',
	Data_BaseData_TimeZones = 'Data.BaseData.TimeZones',
	Data_BaseData_UserContacts = 'Data.BaseData.UserContacts',
	Data_BaseData_Varieties = 'Data.BaseData.Varieties',
	Data_BaseData_Worktypes = 'Data.BaseData.Worktypes',
	Data_BaseData_WorktypeUnit = 'Data.BaseData.WorktypeUnit',
	Data_BaseData_Map_CategoryTheme = 'Data.BaseData.Map.CategoryTheme',
	Data_Authentication_ActiveUser = 'Data.Authentication.ActiveUser',
	Data_Authentication_Blacklist = 'Data.Authentication.Blacklist',
	Data_Authentication_CampaignYear = 'Data.Authentication.CampaignYear',
	Data_Authentication_Farm = 'Data.Authentication.Farm',
	Data_Authentication_FarmMatch = 'Data.Authentication.FarmMatch',
	Data_Authentication_FarmUsers = 'Data.Authentication.FarmUsers',
	Data_Authentication_FarmUsersBase = 'Data.Authentication.FarmUsersBase',
	Data_Authentication_FarmWithDocu = 'Data.Authentication.FarmWithDocu',
	Data_Authentication_Invitations = 'Data.Authentication.Invitations',
	Data_Authentication_Login = 'Data.Authentication.Login',
	Data_Authentication_LoginFailedAttemps = 'Data.Authentication.LoginFailedAttemps',
	Data_Authentication_PasswordRecovery = 'Data.Authentication.PasswordRecovery',
	Data_Authentication_User = 'Data.Authentication.User',
	Data_Authentication_UserSettings = 'Data.Authentication.UserSettings',
	Data_Authentication_UserSettingsMapping = 'Data.Authentication.UserSettingsMapping',
	Data_Authentication_UserSettingsMappingUserType = 'Data.Authentication.UserSettingsMappingUserType',
	Data_Authentication_Enums_ActionType = 'Data.Authentication.Enums.ActionType',
	Data_Authentication_Enums_FarmTypes = 'Data.Authentication.Enums.FarmTypes',
	Data_Authentication_Enums_UserRoleType = 'Data.Authentication.Enums.UserRoleType',
	Data_Attributes_BackendOnlyAttribute = 'Data.Attributes.BackendOnlyAttribute',
	Data_Agronomic_ApplicationRateSlope = 'Data.Agronomic.ApplicationRateSlope',
	Data_Agronomic_ApplicationSettings = 'Data.Agronomic.ApplicationSettings',
	Data_Agronomic_EppoGroup = 'Data.Agronomic.EppoGroup',
	Data_Agronomic_MonitoringRules = 'Data.Agronomic.MonitoringRules',
	Data_Agronomic_RuleDefinition = 'Data.Agronomic.RuleDefinition',
	Data_Agronomic_MonitoringRulesDef_IMonitoringRule = 'Data.Agronomic.MonitoringRulesDef.IMonitoringRule',
	Data_Agronomic_MonitoringRulesDef_Rule01 = 'Data.Agronomic.MonitoringRulesDef.Rule01',
	Data_Agronomic_MonitoringRulesDef_Rule02 = 'Data.Agronomic.MonitoringRulesDef.Rule02',
	Data_Agronomic_MonitoringRulesDef_Rule03 = 'Data.Agronomic.MonitoringRulesDef.Rule03',
	Data_Agronomic_MonitoringRulesDef_Rule04 = 'Data.Agronomic.MonitoringRulesDef.Rule04',
	Data_Agronomic_MonitoringRulesDef_Rule05 = 'Data.Agronomic.MonitoringRulesDef.Rule05',
	Data_Agronomic_MonitoringRulesDef_Rule06 = 'Data.Agronomic.MonitoringRulesDef.Rule06',
	Data_Agronomic_MonitoringRulesDef_Rule07 = 'Data.Agronomic.MonitoringRulesDef.Rule07',
	Data_Agronomic_MonitoringRulesDef_Rule08 = 'Data.Agronomic.MonitoringRulesDef.Rule08',
	Data_Agronomic_MonitoringRulesDef_Rule09 = 'Data.Agronomic.MonitoringRulesDef.Rule09',
	Data_Agronomic_MonitoringRulesDef_Rule10 = 'Data.Agronomic.MonitoringRulesDef.Rule10',
	Data_AccessPermission_AccessPermissions = 'Data.AccessPermission.AccessPermissions',
	Data_AccessPermission_FeatureForUserInFarm = 'Data.AccessPermission.FeatureForUserInFarm',
	Data_AccessPermission_GroupMapping = 'Data.AccessPermission.GroupMapping',
	Data_AccessPermission_GroupMappingUserType = 'Data.AccessPermission.GroupMappingUserType',
	Data_AccessPermission_Usergroup = 'Data.AccessPermission.Usergroup',
	Data_AccessPermission_UsergroupFeature = 'Data.AccessPermission.UsergroupFeature',
	Data_AccessPermission_UsergroupsGroup = 'Data.AccessPermission.UsergroupsGroup',
	Data_AccessPermission_UsergroupUser = 'Data.AccessPermission.UsergroupUser',
	Data_Api_ApDataExtensionMethods = 'Data.Api.ApDataExtensionMethods',
	Data_Api_IBaseEntityId = 'Data.Api.IBaseEntityId',
	Data_Api_BaseEntity = 'Data.Api.BaseEntity',
	Data_Api_ISendToAllClientsActionType = 'Data.Api.ISendToAllClientsActionType',
	Data_Api_MqttMessage = 'Data.Api.MqttMessage',
	Data_Api_Validation_ApDataAnnotationsValidate = 'Data.Api.Validation.ApDataAnnotationsValidate',
	Data_Api_Validation_ApValidationException = 'Data.Api.Validation.ApValidationException',
	Data_Api_Validation_ApValidationLevel = 'Data.Api.Validation.ApValidationLevel',
	Data_Api_Validation_ApValidationResult = 'Data.Api.Validation.ApValidationResult',
	Data_Api_Validation_ApValidator = 'Data.Api.Validation.ApValidator',
	Data_Api_Validation_IApValidatable = 'Data.Api.Validation.IApValidatable',
	Agriport_Excel_Factory_ReportBook = 'Agriport.Excel.Factory.ReportBook',
	Agriport_Excel_Factory_FieldDiaryReportBook = 'Agriport.Excel.Factory.FieldDiaryReportBook',
	Agriport_Excel_Factory_FungiDetectReportBook = 'Agriport.Excel.Factory.FungiDetectReportBook',
	Agriport_Excel_Factory_PerformanceReportBook = 'Agriport.Excel.Factory.PerformanceReportBook',
	Agriport_Excel_Factory_SoilSampleFieldReportBook = 'Agriport.Excel.Factory.SoilSampleFieldReportBook',
	Agriport_Excel_Factory_ReportBooks_GenericReportBook = 'Agriport.Excel.Factory.ReportBooks.GenericReportBook',
	Agriport_Excel_Factory_ReportBooks_NRestrictionsReportBook = 'Agriport.Excel.Factory.ReportBooks.NRestrictionsReportBook',
	Agriport_Excel_Factory_ReportBooks_NutrientDistributionsReportBook = 'Agriport.Excel.Factory.ReportBooks.NutrientDistributionsReportBook',
	Agriport_Excel_Factory_ReportBooks_SoilSamplePlanningReportBook = 'Agriport.Excel.Factory.ReportBooks.SoilSamplePlanningReportBook',
	Agriport_Excel_Factory_InputData_ITableHeader = 'Agriport.Excel.Factory.InputData.ITableHeader',
	Agriport_Excel_Factory_InputData_IWorksheetPrintable = 'Agriport.Excel.Factory.InputData.IWorksheetPrintable',
	Agriport_Excel_Factory_InputData_SoilSamplePlanningReport_Content = 'Agriport.Excel.Factory.InputData.SoilSamplePlanningReport.Content',
	Agriport_Excel_Factory_InputData_SoilSamplePlanningReport_ContentRow = 'Agriport.Excel.Factory.InputData.SoilSamplePlanningReport.ContentRow',
	Agriport_Excel_Factory_InputData_SoilSamplePlanningReport_Header = 'Agriport.Excel.Factory.InputData.SoilSamplePlanningReport.Header',
	Agriport_Excel_Factory_InputData_SoilSamplePlanningReport_ReportData = 'Agriport.Excel.Factory.InputData.SoilSamplePlanningReport.ReportData',
	Agriport_Excel_Factory_InputData_SoilSampleField_Content = 'Agriport.Excel.Factory.InputData.SoilSampleField.Content',
	Agriport_Excel_Factory_InputData_SoilSampleField_ContentRow = 'Agriport.Excel.Factory.InputData.SoilSampleField.ContentRow',
	Agriport_Excel_Factory_InputData_SoilSampleField_Header = 'Agriport.Excel.Factory.InputData.SoilSampleField.Header',
	Agriport_Excel_Factory_InputData_SoilSampleField_ReportData = 'Agriport.Excel.Factory.InputData.SoilSampleField.ReportData',
	Agriport_Excel_Factory_InputData_PerformanceReport_Content = 'Agriport.Excel.Factory.InputData.PerformanceReport.Content',
	Agriport_Excel_Factory_InputData_PerformanceReport_ContentRow = 'Agriport.Excel.Factory.InputData.PerformanceReport.ContentRow',
	Agriport_Excel_Factory_InputData_PerformanceReport_Header = 'Agriport.Excel.Factory.InputData.PerformanceReport.Header',
	Agriport_Excel_Factory_InputData_PerformanceReport_ReportData = 'Agriport.Excel.Factory.InputData.PerformanceReport.ReportData',
	Agriport_Excel_Factory_InputData_NRestriction_Content = 'Agriport.Excel.Factory.InputData.NRestriction.Content',
	Agriport_Excel_Factory_InputData_NRestriction_ContentRow = 'Agriport.Excel.Factory.InputData.NRestriction.ContentRow',
	Agriport_Excel_Factory_InputData_NRestriction_Header = 'Agriport.Excel.Factory.InputData.NRestriction.Header',
	Agriport_Excel_Factory_InputData_NRestriction_ReportData = 'Agriport.Excel.Factory.InputData.NRestriction.ReportData',
	Agriport_Excel_Factory_InputData_FungiDetect_Content = 'Agriport.Excel.Factory.InputData.FungiDetect.Content',
	Agriport_Excel_Factory_InputData_FungiDetect_ContentRow = 'Agriport.Excel.Factory.InputData.FungiDetect.ContentRow',
	Agriport_Excel_Factory_InputData_FungiDetect_Header = 'Agriport.Excel.Factory.InputData.FungiDetect.Header',
	Agriport_Excel_Factory_InputData_FungiDetect_ReportData = 'Agriport.Excel.Factory.InputData.FungiDetect.ReportData',
	Agriport_Excel_Factory_InputData_FieldDiary_Content = 'Agriport.Excel.Factory.InputData.FieldDiary.Content',
	Agriport_Excel_Factory_InputData_FieldDiary_ContentRow = 'Agriport.Excel.Factory.InputData.FieldDiary.ContentRow',
	Agriport_Excel_Factory_InputData_FieldDiary_Header = 'Agriport.Excel.Factory.InputData.FieldDiary.Header',
	Agriport_Excel_Factory_InputData_FieldDiary_IFieldDiaryHeader = 'Agriport.Excel.Factory.InputData.FieldDiary.IFieldDiaryHeader',
	Agriport_Excel_Factory_InputData_FieldDiary_ReportData = 'Agriport.Excel.Factory.InputData.FieldDiary.ReportData',
	Agriport_Excel_Factory_InputData_FieldDiary_Summary = 'Agriport.Excel.Factory.InputData.FieldDiary.Summary',
}

export enum NetTypes {
	GUID = 'System.Guid',
	BYTE = 'System.Byte',
	LONG = 'System.Int64',
	INT = 'System.Int32',
	DOUBLE = 'System.Double',
	FLOAT = 'System.Single',
	BOOL = 'System.Boolean',
	STRING = 'System.String',
	DATETIME = 'System.DateTime',
	TIMESPAN = 'System.TimeSpan',
	OBJECT = 'System.Object',
}

export enum ApPermissions {
	SHOW_FIELDS = 'show_fields',
	EDIT_FIELDS = 'edit_fields',
	SHOW_GLOBAL_CROPROTATION = 'show_global_croprotation',
	EDIT_GLOBAL_CROPROTATION = 'edit_global_croprotation',
	EDIT_USER_PROFILE = 'edit_user_profile',
	SHOW_KRIGING = 'show_kriging',
	EDIT_LICENSE = 'edit_license',
	SHOW_LICENSE = 'show_license',
	SHOW_USERS = 'show_users',
	EDIT_TRANSLATIONS = 'edit_translations',
	SHOW_TRANSLATIONS = 'show_translations',
	SHOW_ACCESS_RIGHTS = 'show_access_rights',
	EDIT_ACCESS_RIGHTS = 'edit_access_rights',
	SHOW_SETTINGS = 'show_settings',
	EDIT_SETTINGS = 'edit_settings',
	SHOW_CROPTYPE_GROUPS = 'show_croptype_groups',
	EDIT_CROPTYPE_GROUPS = 'edit_croptype_groups',
	SHOW_LOGGER = 'show_logger',
	SHOW_PERFORMANCE_EVALUATION = 'show_performance_evaluation',
	EDIT_LOGGER = 'edit_logger',
	SHOW_WORKTYPES = 'show_worktypes',
	EDIT_WORKTYPES = 'edit_worktypes',
	SHOW_SOIL_SAMPLE_FIELDS = 'show_soil_sample_fields',
	SHOW_CROPTYPES = 'show_croptypes',
	EDIT_CROPTYPES = 'edit_croptypes',
	EDIT_FERTILIZER = 'edit_fertilizer',
	SHOW_FERTILIZER = 'show_fertilizer',
	SHOW_PLANT_PROTECTION = 'show_plant_protection',
	EDIT_PLANT_PROTECTION = 'edit_plant_protection',
	EDIT_VARIETIES = 'edit_varieties',
	SHOW_VARIETIES = 'show_varieties',
	SHOW_RESOURCES = 'show_resources',
	EDIT_RESOURCES = 'edit_resources',
	EDIT_SUBFARMS = 'edit_subfarms',
	SHOW_SUBFARMS = 'show_subfarms',
	SHOW_FIELD_DIARY = 'show_field_diary',
	SHOW_CURRENT_LOCATIONS = 'show_current_locations',
	EDIT_USERS = 'edit_users',
	SHOW_USER_PROFILE = 'show_user_profile',
	SHOW_IMPORTS = 'show_imports',
	EDIT_IMPORTS = 'edit_imports',
	EDIT_UPLOADS = 'edit_uploads',
	SHOW_FORMATS = 'show_formats',
	SHOW_MEASURES = 'show_measures',
	SHOW_LANES = 'show_lanes',
	SHOW_FUNGIDETECT = 'show_fungidetect',
	SHOW_NFERTILIZATION = 'show_nfertilization',
	EDIT_KRIGING = 'edit_kriging',
	SHOW_STATISTICS = 'show_statistics',
	SHOW_PARAMETER_DEFINITION = 'show_parameter_definition',
	SHOW_TRACE_LOG = 'show_trace_log',
	SHOW_NUTRIENTS = 'show_nutrients',
	SHOW_NFERTILIZATION_LOGFILES = 'show_nfertilization_logfiles',
	SHOW_NFERTILIZATION_N_NEED = 'show_nfertilization_n_need',
	SHOW_NFERTILIZATION_PLANNING = 'show_nfertilization_planning',
	EDIT_NFERTILIZATION_PLANNING = 'edit_nfertilization_planning',
	SHOW_NFERTILIZATION_BOOKING = 'show_nfertilization_booking',
	EDIT_NFERTILIZATION_BOOKING = 'edit_nfertilization_booking',
	SHOW_PLANTPROTECTION_LOGFILES = 'show_plantprotection_logfiles',
	SHOW_PLANTPROTECTION_PLANNING = 'show_plantprotection_planning',
	EDIT_PLANTPROTECTION_PLANNING = 'edit_plantprotection_planning',
	SHOW_PLANTPROTECTION_BOOKING = 'show_plantprotection_booking',
	EDIT_PLANTPROTECTION_BOOKING = 'edit_plantprotection_booking',
	SHOW_FUNGIDETECT_ORTHOPHOTO = 'show_fungidetect_orthophoto',
	SHOW_BOOKING_BOOKING = 'show_booking_booking',
	EDIT_BOOKING_BOOKING = 'edit_booking_booking',
	SHOW_BOOKING_ARCHIVE = 'show_booking_archive',
	EDIT_BOOKING_ARCHIVE = 'edit_booking_archive',
	SHOW_PLANTPROTECTION_OVERVIEW = 'show_plantprotection_overview',
	SHOW_ADMINISTRATION_JOBS = 'show_administration_jobs',
	EDIT_HELP_ARTICLES = 'edit_help_articles',
	SHOW_SOILSAMPLE = 'show_soilsample',
	EDIT_SOILSAMPLE = 'edit_soilsample',
	EDIT_SOILSAMPLE_PLANNING_VARIABLY = 'edit_soilsample_planning_variably',
	EDIT_SOILSAMPLE_PLANNING_CONSTANT = 'edit_soilsample_planning_constant',
	EDIT_BOOKINGS_CONSTANT = 'edit_bookings_constant',
	EDIT_BOOKINGS_VARIABLY = 'edit_bookings_variably',
	SHOW_BOOKINGS = 'show_bookings',
	SHOW_SOILSAMPLE_PLANNING = 'show_soilsample_planning',
	SHOW_NUTRIENTS_DISTRIBUTION = 'show_nutrients_distribution',
	SHOW_NUTRIENTS_P_NEED = 'show_nutrients_p_need',
	SHOW_NUTRIENTS_PLANNING = 'show_nutrients_planning',
	EDIT_NUTRIENTS_PLANNING = 'edit_nutrients_planning',
	SHOW_NUTRIENTS_BOOKING = 'show_nutrients_booking',
	EDIT_NUTRIENTS_BOOKING = 'edit_nutrients_booking',
}

export enum HibernateModelNames {
	SELECTABLE_CROPS = 'SelectableCrops',
	CROP_TYPE_DETAILS = 'CropTypesDetails',
	SELECTABLE_VARIETIES = 'SelectableVariety',
	SELECTABLE_FERTILIZER = 'SelectableFertilizer',
	SELECTABLE_PLANT_PROTECTION_PRODUCT = 'SelectablePpProduct',
	FIELD_CROPS = 'FieldCrop',
}
